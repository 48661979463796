<template>
  <div class="start-window">
    <template v-if="!sent">
      <div class="b21">{{ $t('AUTH_SIGNUP_CREATE') }}</div>
      <div class="r14">
        {{ $t('AUTH_SIGNUP_ALREADY_HAVE') }}
        <a href="/" @click.prevent.stop="$router.push('login')">{{
          $t('AUTH_COMMON_ENTER')
        }}</a>
      </div>

      <form action>
        <label for="t1" class="text">
          <span>{{ $t('AUTH_COMMON_EMAIL') }}</span>
          <input
            type="text"
            class="inp-st"
            id="t1"
            placeholder
            v-model="email"
            @change="emailChange"
            @input="emailChange"
            tabindex="1"
          />
          <p class="login-alerts" v-if="emailMessage">{{ emailMessage }}</p>
        </label>
        <label for="t2" class="password">
          <span>{{ $t('AUTH_COMMON_PASSWORD') }}</span>
          <input
            type="password"
            class="inp-st"
            id="t2"
            placeholder
            v-model="password"
            @change="passwordChange"
            @input="passwordChange"
            tabindex="2"
          />
        </label>
        <label for="t3" class="password">
          <span>{{ $t('AUTH_COMMON_CONFIRM') }}</span>
          <input
            type="password"
            class="inp-st"
            id="t3"
            placeholder
            v-model="confirmPass"
            @change="passwordChange"
            @input="passwordChange"
            tabindex="3"
          />
          <p class="login-alerts" v-if="passwordMessage">
            {{ passwordMessage }}
          </p>
        </label>

        <button class="btn-st-bg disabled" @click.prevent.stop="signup">
          {{ $t('AUTH_COMMON_SIGN_UP') }}
        </button>
      </form>
    </template>
    <template v-if="sent">
      <p class="message-login">{{ $t('AUTH_SIGNUP_SENT') }}</p>
      <router-link to="/auth/login">{{ $t('AUTH_COMMON_SIGNIN_TO') }}</router-link>
    </template>
  </div>
</template>
<script>
import { AUTH_REGISTER } from "@/store/actions/auth";
import { mapGetters } from "vuex";
import pageUtils from "../../models/page-utils";

export default {
  name: "Signup",
  computed: {
    ...mapGetters(["authStatus"]),
    locale() {
      return this.$locale.AUTH;
    },
    isValidPassword() {
      return pageUtils.isValidPassword(this.password);
    },
    isValidEmail() {
      return pageUtils.isValidEmail(this.email);
    },
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPass: "",
      sent: false,
      errorCode: "",
      isRequesting: false,
      emailMessage: "",
      passwordMessage: "",
    };
  },
  methods: {
    emailChange(event) {
      this.emailMessage = "";
      this.passwordMessage = "";
      const email = this.email;
      if (email.length == 0) {
        this.emailMessage = "";
      } else {
        const result = this.isValidEmail;
        if (!result) {
          this.emailMessage = this.$t('AUTH_COMMON_VALID_EMAIL');
        } else {
          this.emailMessage = "";
        }
      }
    },
    passwordChange(event) {
      this.emailMessage = "";
      this.passwordMessage = "";
      if (this.password.length == 0) {
        this.passwordMessage = "";
        return false;
      } else {
        const result = this.isValidPassword;
        const samePasswords = this.password === this.confirmPass;

        if (!result) {
          this.passwordMessage = this.$t('AUTH_COMMON_VALID_PASSWORD');
          return true;
        } else {
          if (!samePasswords) {
            this.passwordMessage = this.$t('AUTH_SIGNUP_NOT_EQUAL');
            return true;
          }
          this.passwordMessage = "";
          return false;
        }
      }
    },
    async signup() {
      if (this.password === this.confirmPass) {
        const password = this.isValidPassword;
        const email = this.isValidEmail;
        if (password && email && !this.isRequesting) {
          try {
            this.isRequesting = true;
            let lang = this.$i18n.locale || 'EN';
            lang = lang.toUpperCase();
            await this.$store.dispatch(AUTH_REGISTER, {
              password: this.password,
              email: this.email,
              lang
            });
            this.sent = true;
          } catch (err) {
            this.passwordMessage = this.$t('AUTH_ERROR_'+err.code);
          }
          this.isRequesting = false;
        }
      }
    },
  },
};
</script>
